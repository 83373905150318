import { initializeApp } from "firebase/app";
import {getAuth} from "firebase/auth";
import firebase from "firebase/compat/app"
import {getMessaging,onMessage} from "firebase/messaging";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyC9dAARAQNhXdksfi9tUEp_T71a-xckgAg",
  authDomain: "newwebbhadracity.firebaseapp.com",
  projectId: "newwebbhadracity",
  storageBucket: "newwebbhadracity.appspot.com",
  messagingSenderId: "311181472762",
  appId: "1:311181472762:web:753599b7e49b9aa6e3aa43",
  measurementId: "G-PQHY5HJ4PX"
};



    // eslint-disable-next-line
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
  } else {
      firebase.app(); // if already initialized, use that one
  }

  const app = initializeApp(firebaseConfig);
  const messaging = getMessaging(app)
  // onMessage(messaging, (payload) => {
  //   console.log("payload", payload)

  // });

const authentication = getAuth();


export {app, authentication,messaging};
